import { useState, useRef, useEffect } from "react";
import WebRTCfishcam from "./WebRTC.js";
import "./Fishcam.css";

// TODO: bad mount should still show dropdown

export default function Fishcam() {
  const zoomableRef = useRef(null);
  const [selection, setSelection] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    isSelecting: false,
  });

  const handleMouseDown = (e) => {
    const { clientX, clientY } = e;
    setSelection({
      x: clientX,
      y: clientY,
      width: 0,
      height: 0,
      isSelecting: true,
    });
  };

  const handleMouseMove = (e) => {
    if (!selection.isSelecting) return;
    const { clientX, clientY } = e;
    const width = clientX - selection.x;
    const height = clientY - selection.y;

    setSelection((prevState) => ({
      ...prevState,
      width: Math.abs(width),
      height: Math.abs(height),
      x: width < 0 ? clientX : prevState.x,
      y: height < 0 ? clientY : prevState.y,
    }));
  };

  const handleMouseUp = () => {
    if (!selection.isSelecting) return;
    setSelection((prevState) => ({ ...prevState, isSelecting: false }));

    const rect = {
      left: selection.x,
      top: selection.y,
      width: selection.width,
      height: selection.height,
    };
    zoomToRectangle(rect);
  };

  const zoomToRectangle = (rect) => {
    const element = zoomableRef.current;
    const elementRect = element.getBoundingClientRect();

    const scaleX = elementRect.width / rect.width;
    const scaleY = elementRect.height / rect.height;
    const scale = Math.min(scaleX, scaleY);

    const offsetX = rect.left - elementRect.left;
    const offsetY = rect.top - elementRect.top;

    const transformOriginX =
      ((offsetX + rect.width / 2) / elementRect.width) * 100;
    const transformOriginY =
      ((offsetY + rect.height / 2) / elementRect.height) * 100;

    element.style.transformOrigin = `${transformOriginX}% ${transformOriginY}%`;
    element.style.transform = `scale(${scale})`;
  };
  useEffect(() => {
    WebRTCfishcam.init();
    return WebRTCfishcam.stop;
  }, []);

  let selectionStyle = {
    left: selection.x,
    top: selection.y,
    width: selection.width,
    height: selection.height,
    display: selection.isSelecting ? "block" : "none",
  };

  return (
    <div
      id="camContainer"
      ref={zoomableRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <textarea></textarea>
      <div id="selection-rectangle" style={selectionStyle} />
      <video id="camVideo" playsInline autoPlay muted></video>
      <div className="selectors">
        <div className="select">
          <label htmlFor="videoSource" />
          <select id="videoSource" onChange={WebRTCfishcam.start}></select>
          <select id="audioSource" className="hidden"></select>
          <select id="audioOutput" className="hidden"></select>
        </div>
      </div>
    </div>
  );
}
